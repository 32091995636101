<template>
    <v-container fluid style="background: linear-gradient(#043339, #fff);" class="ma-0 pa-0">
        
        <!-- DESKTOP -->
        <v-row class="d-none d-lg-block mx-16 px-16 py-16" no-gutters>
            <v-col cols="12" lg="6">
                <v-row>
                    <v-col cols="12" class="text-center">
                        <span class="text-h3 white--text font-weight-medium" >{{$t('footer.ideas1')}}</span>
                    </v-col>
                    <v-col cols="12" class="text-center">
                        <span class="text-h4 white--text font-weight-medium" >{{$t('footer.ideas2')}}</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-card>
                            <v-card-title style="background-color: #094A59;" class="white--text">
                                <v-icon color="white" class="mr-2">mdi-message</v-icon>{{$t('footer.send')}}
                            </v-card-title>
                            <v-card-text>
                                <validation-observer ref="formSender" v-slot="{ invalid }">
                                    <v-form @submit.prevent="submit" :class="{'shake' : animated}">
                                        <fieldset :disabled="loading">
                                            <v-card class="elevation-0">
                                                <v-card-title class="yellow-back">
                                                    <span class="white--text text-subtitle-1 font-weight-bold"><v-icon small color="white" class="mr-2">mdi-comment</v-icon>{{ $t('CONTACTS.NEW_MESSAGE') }}</span>
                                                </v-card-title>
                                                <v-card-text>
                                                    <v-row class="mt-n16">
                                                        <v-col cols="12" md="12">
                                                            <validation-provider v-slot="{ errors }" vid="nome" :name="$t('CONTACTS.NAME')" rules="required">
                                                                <v-text-field
                                                                    class="font-weight-bold"
                                                                    v-model="fields.name"
                                                                    :label="$t('CONTACTS.NAME')"
                                                                    :error-messages="errors"
                                                                ></v-text-field>
                                                            </validation-provider>
                                                        </v-col>
                                                        <v-col cols="12">
                                                            <validation-provider v-slot="{ errors }" vid="Email" name="Email" rules="required|email">
                                                                <v-text-field
                                                                    class="font-weight-bold"
                                                                    v-model="fields.email"
                                                                    label="Email"
                                                                    :error-messages="errors"
                                                                ></v-text-field>
                                                            </validation-provider>
                                                        </v-col>
                                                        <v-col cols="12" md="6">
                                                            <v-text-field
                                                                    class="font-weight-bold"
                                                                    v-model="fields.company"
                                                                    :label="$t('CONTACTS.EMPRESA')"
                                                                ></v-text-field>
                                                        </v-col>
                                                        <v-col cols="12" md="6">
                                                            <validation-provider v-slot="{ errors }" vid="Numerp" :name="$t('CONTACTS.NUMERO')" rules="required">
                                                                <v-text-field
                                                                    class="font-weight-bold"
                                                                    v-model="fields.numero"
                                                                    :label="$t('CONTACTS.NUMERO')"
                                                                    :error-messages="errors"
                                                                ></v-text-field>
                                                            </validation-provider>
                                                        </v-col>
                                                        <v-col cols="12" md="6">
                                                            <v-autocomplete
                                                                class="font-weight-bold"
                                                                :items="countries"
                                                                item-text="nome"
                                                                item-id="nome"
                                                                v-model="fields.country"
                                                                :label="$t('CONTACTS.PAIS')"
                                                            ></v-autocomplete>
                                                        </v-col>
                                                        <v-col cols="12" md="6">
                                                            <v-autocomplete
                                                                class="font-weight-bold"
                                                                :items="size_company[$i18n.locale]"
                                                                v-model="fields.size"
                                                                :label="$t('CONTACTS.SIZE')"
                                                            ></v-autocomplete>
                                                        </v-col>
                                                        <v-col cols="12" v-if="false">
                                                            <validation-provider v-slot="{ errors }" vid="Título" :name="$t('CONTACTS.TITLE')" rules="required">
                                                                <v-text-field
                                                                    class="font-weight-bold"
                                                                    v-model="fields.title"
                                                                    :label="$t('CONTACTS.TITLE')"
                                                                    :error-messages="errors"
                                                                ></v-text-field>
                                                            </validation-provider>
                                                        </v-col>
                                                        <v-col cols="12">                                                                
                                                            <v-textarea
                                                                class="font-weight-bold"
                                                                v-model="fields.description"
                                                                :label="$t('CONTACTS.DESCRIPTION')"
                                                                rows="2"
                                                            ></v-textarea>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            <v-card-actions>
                                                <v-row align="center" justify="space-around">
                                                    <v-col align="center" justify="space-around">
                                                        <v-btn :disabled="invalid" depressed color="#052947" class="mr-5 ml-5 font-weight-bold white--text" type="submit">
                                                            {{ $t('CONTACTS.SEND') }}
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-card-actions>
                                            </v-card>
                                        </fieldset>
                                    </v-form>
                                </validation-observer>
                            </v-card-text>
                        </v-card> 
                    </v-col>
                </v-row>
            </v-col>
            <div style="z-index: 0 !important; position: absolute; bottom: 0; right: 0; height: 85%; width: 50%;">
                <div style="position: absolute; bottom: 0; height: 100%; clip-path: polygon(27% 17%, 14% 34%, 28% 32%); width: 100%; background-color: #EF9576; z-index: 3;">  
                </div>
                <div style="position: absolute; bottom: 0; height: 100%; clip-path: polygon(54% 2%, 45% 24%, 58% 24%); width: 100%; background-color: #14545B; z-index: 3;">  
                </div>
                <div style="position: absolute; bottom: 0; height: 100%; clip-path: polygon(66% 36%, 75% 41%, 76% 34%); width: 100%; background-color: #0D1F2B; z-index: 3;">  
                </div>
                <div style="position: absolute; bottom: 0; height: 100%; clip-path: polygon(23% 91%, 35% 92%, 31% 77%); width: 100%; background-color: #E76012; z-index: 3;">  
                </div>
                <div style="position: absolute; bottom: 0; height: 100%; clip-path: polygon(10% 80%, 23% 81%, 20% 68%); width: 100%; background-color: white; z-index: 3;">  
                </div>
                <div style="position: absolute; bottom: 0; height: 100%; clip-path: polygon(26% 0, 100% 54%, 100% 75%, 35% 100%, 9% 100%); width: 100%;" class="accent">  
                    <div style="width:50%; height: 50%; position: relative; top: 60%; transform: translate(50%, -50%);" class="white--text">
                        <v-row style="display: block; margin: auto;">
                            <v-col cols="12" class="text-h4">
                                {{ $t('PRE_FOOTER.contacts') }}    
                            </v-col>  
                        </v-row>
                        <v-row>                          
                            <v-col cols="12" md="auto" >
                                <v-icon color="white">mdi-email</v-icon>
                            </v-col>                  
                            <v-col cols="12" md="auto" class="font-weight-light text-h6">
                                info@dreamdealtech.com
                            </v-col>   
                        </v-row>
                        <v-row>                          
                            <v-col cols="12" md="auto">
                                <v-icon color="white">mdi-clock</v-icon>
                            </v-col>                          
                            <v-col cols="12" md="auto" class="font-weight-light text-h6">
                                {{ $t('PRE_FOOTER.work_week') }} - 9h {{ $t('PRE_FOOTER.to') }} 18h
                            </v-col>
                        </v-row>
                        <v-row>                          
                            <v-col cols="12" md="auto">
                                <v-icon color="white">mdi-phone</v-icon>
                            </v-col>                          
                            <v-col cols="12" md="auto" class="font-weight-light text-h6">
                                (+351) 220 280 430
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </div>
        </v-row>


        <!-- MOBILE -->
        <v-row class="d-lg-none mx-2 px-2 py-8" no-gutters>
            <v-col cols="12" lg="6">
                <v-row>
                    <v-col cols="12" class="text-center">
                        <span class="text-h5 white--text font-weight-medium" >{{$t('footer.ideas1')}}</span>
                    </v-col>
                    <v-col cols="12" class="text-center">
                        <span class="text-h6 white--text font-weight-medium" >{{$t('footer.ideas2')}}</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-card>
                            <v-card-title style="background-color: #094A59;" class="white--text text-caption">
                                <v-icon color="white" class="mr-2">mdi-message</v-icon>Nova mensagem!
                            </v-card-title>
                            <v-card-text>
                                <validation-observer ref="formSender" v-slot="{ invalid }">
                                    <v-form @submit.prevent="submit" :class="{'shake' : animated}">
                                        <fieldset :disabled="loading">
                                            <v-card class="elevation-0">
                                                <v-card-title class="yellow-back">
                                                    <span class="white--text text-subtitle-1 font-weight-bold"><v-icon small color="white" class="mr-2">mdi-comment</v-icon>{{ $t('CONTACTS.NEW_MESSAGE') }}</span>
                                                </v-card-title>
                                                <v-card-text class="text-caption">
                                                    <v-row>
                                                        <v-col cols="12" md="12">
                                                            <validation-provider v-slot="{ errors }" vid="nome" :name="$t('CONTACTS.NAME')" rules="required">
                                                                <v-text-field
                                                                    class="font-weight-bold"
                                                                    v-model="fields.name"
                                                                    :label="$t('CONTACTS.NAME')"
                                                                    :error-messages="errors"
                                                                ></v-text-field>
                                                            </validation-provider>
                                                        </v-col>
                                                        <v-col cols="12">
                                                            <validation-provider v-slot="{ errors }" vid="Email" name="Email" rules="required|email">
                                                                <v-text-field
                                                                    class="font-weight-bold"
                                                                    v-model="fields.email"
                                                                    label="Email"
                                                                    :error-messages="errors"
                                                                ></v-text-field>
                                                            </validation-provider>
                                                        </v-col>
                                                        <v-col cols="12" md="6">
                                                            <v-text-field
                                                                    class="font-weight-bold"
                                                                    v-model="fields.company"
                                                                    :label="$t('CONTACTS.EMPRESA')"
                                                                ></v-text-field>
                                                        </v-col>
                                                        <v-col cols="12" md="6">
                                                            <validation-provider v-slot="{ errors }" vid="Numerp" :name="$t('CONTACTS.NUMERO')" rules="required">
                                                                <v-text-field
                                                                    class="font-weight-bold"
                                                                    v-model="fields.numero"
                                                                    :label="$t('CONTACTS.NUMERO')"
                                                                    :error-messages="errors"
                                                                ></v-text-field>
                                                            </validation-provider>
                                                        </v-col>
                                                        <v-col cols="12" md="6">
                                                            <v-autocomplete
                                                                class="font-weight-bold"
                                                                :items="countries"
                                                                item-text="nome"
                                                                item-id="nome"
                                                                v-model="fields.country"
                                                                :label="$t('CONTACTS.PAIS')"
                                                            ></v-autocomplete>
                                                        </v-col>
                                                        <v-col cols="12" md="6">
                                                            <v-autocomplete
                                                                class="font-weight-bold"
                                                                :items="size_company[$i18n.locale]"
                                                                v-model="fields.size"
                                                                :label="$t('CONTACTS.SIZE')"
                                                            ></v-autocomplete>
                                                        </v-col>
                                                        <v-col cols="12" v-if="false">
                                                            <validation-provider v-slot="{ errors }" vid="Título" :name="$t('CONTACTS.TITLE')" rules="required">
                                                                <v-text-field
                                                                    class="font-weight-bold"
                                                                    v-model="fields.title"
                                                                    :label="$t('CONTACTS.TITLE')"
                                                                    :error-messages="errors"
                                                                ></v-text-field>
                                                            </validation-provider>
                                                        </v-col>
                                                        <v-col cols="12">                                                                
                                                            <v-textarea
                                                                class="font-weight-bold"
                                                                v-model="fields.description"
                                                                :label="$t('CONTACTS.DESCRIPTION')"
                                                                rows="2"
                                                            ></v-textarea>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            <v-card-actions>
                                                <v-row align="center" justify="space-around">
                                                    <v-col align="center" justify="space-around">
                                                        <v-btn :disabled="invalid" depressed color="#052947" class="mr-5 ml-5 font-weight-bold white--text" type="submit">
                                                            {{ $t('CONTACTS.SEND') }}
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-card-actions>
                                            </v-card>
                                        </fieldset>
                                    </v-form>
                                </validation-observer>
                            </v-card-text>
                        </v-card> 
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row no-gutters class="d-lg-none ma-0 pa-0">
            <v-col cols="12" class="mt-2 ma-0 pa-0" v-if="true">                
                <div style="z-index: 0 !important; position: relative; height: 100vh; background-color: transparent;">

                    <div style="position: absolute; bottom: 0; height: 100%; clip-path: polygon(27% 17%, 14% 34%, 28% 32%); width: 100%; background-color: #EF9576; z-index: 3;">  
                    </div>
                    <div style="position: absolute; bottom: 0; height: 100%; clip-path: polygon(54% 2%, 45% 24%, 58% 24%); width: 100%; background-color: #14545B; z-index: 3;">  
                    </div>
                    <div style="position: absolute; bottom: 0; height: 100%; clip-path: polygon(66% 36%, 75% 41%, 76% 34%); width: 100%; background-color: #0D1F2B; z-index: 3;">  
                    </div>
                    <div style="position: absolute; bottom: 0; height: 100%; clip-path: polygon(23% 91%, 35% 92%, 31% 77%); width: 100%; background-color: #E76012; z-index: 3;">  
                    </div>
                    <div style="position: absolute; bottom: 0; height: 100%; clip-path: polygon(10% 60%, 13% 71%, 20% 58%); width: 100%; background-color: white; z-index: 3;">  
                    </div>


                    <div :class="window.width <= 380 ? 'mobile-small accent ma-0 pa-0' : 'mobile-medium accent ma-0 pa-0'">

                        

                        <div style="z-index: 99; width:50%; height: 50%; position: relative; top: 60%; transform: translate(50%, -50%);" class="white--text text-center">
                            <v-row>
                                <v-col cols="12" class="text-h6">
                                    {{ $t('PRE_FOOTER.contacts') }}    
                                </v-col>  
                            </v-row>
                            <v-row>                       
                                <v-col cols="12" md="12" class="font-weight-light text-caption">
                                    <v-icon small color="white">mdi-email</v-icon>info@dreamdealtech.com
                                </v-col>   
                            </v-row>
                            <v-row>                                     
                                <v-col cols="12" md="12" class="font-weight-light text-caption">
                                    <v-icon small color="white">mdi-clock</v-icon>{{ $t('PRE_FOOTER.work_week') }} - 9h {{ $t('PRE_FOOTER.to') }} 18h
                                </v-col>
                            </v-row>
                            <v-row>                                      
                                <v-col cols="12" md="12" class="font-weight-light text-caption">
                                    <v-icon small color="white">mdi-phone</v-icon>(+351) 220 280 430
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </div>
            </v-col>
        </v-row>
        <div class="d-none d-lg-block"  style="position: absolute; top: 0; right: 0; height: 300px; width: 350px;">
            <div style="position: absolute; bottom: 0; height: 100%; clip-path: polygon(86% 16%, 96% 22%, 98% 8%); width: 100%; background-color: #0D1F2B; z-index: 3;">  
            </div>
            <div style="position: absolute; bottom: 0; height: 100%; clip-path: polygon(60% 14%, 75% 53%, 99% 29%); width: 100%; background-color: white; z-index: 3;">  
            </div>
            <div style="position: absolute; bottom: 0; height: 100%; clip-path: polygon(77% 69%, 88% 78%, 91% 55%); width: 100%; background-color: #063F57; z-index: 3;">  
            </div>
            <div style="position: absolute; bottom: 0; height: 100%; clip-path: polygon(12% 0, 21% 0, 29% 29%, 0 28%, 12% 0); width: 100%; background-color: #052947; z-index: 3;">  
            </div>
        </div>
    </v-container>
</template>
<style>
.mobile-medium{
    position: absolute; 
    bottom: 0; 
    height: 100%;
    clip-path: polygon(16% 0, 100% 53%, 100% 75%, 6% 100%, 5% 100%);
    width: 100%;
}

.mobile-small{
    position: absolute; 
    bottom: 0; 
    height: 100%; 
    clip-path: polygon(45% 0, 100% 53%, 100% 81%, 0 89%, 0 61%);



    width: 100%;
}

.blue-big-triangle {
    border: 60px solid transparent;
    position: absolute;
    border-bottom: 140px solid #052947;
    rotate: 200deg;
    z-index: 0;
    top: 0;
    right: 35vh;
}
.white-big-triangle {
    border: 60px solid transparent;
    position: absolute;
    border-bottom: 140px solid white;
    rotate: -25deg;
    z-index: 0;
    top: 5vh;
    right: 15vh;
}
.bellow-white {
    border: 30px solid transparent;
    position: absolute;
    border-bottom: 70px solid #063F57;
    rotate: 45deg;
    z-index: 0;
    top: 30vh;
    right: 0;
}
.above-white {
    border: 20px solid transparent;
    position: absolute;
    border-bottom: 40px solid #0D1F2B;
    rotate: 45deg;
    z-index: 0;
    top: 1vh;
    right: 0;
}


.orange-light-triangle {
    border: 30px solid transparent;
    position: relative;
    border-bottom: 70px solid #EF9576;
    rotate: -145deg;
    z-index: 0;
    top: -75vh;
    left: -37vh;
}


.great-triangle {
    border: 250px solid transparent;
    border-bottom: 350px solid #052947;
    z-index: 0;
}


.triangulo-cover{
    background: url('../assets/covers/triangulo.png') no-repeat;
    background-size: 100%;
}
.blue-big-triangle {
    border: 60px solid transparent;
    position: absolute;
    border-bottom: 140px solid #052947;
    rotate: 200deg;
    z-index: 0;
    top: 0;
    right: 35vh;
}
.white-big-triangle {
    border: 60px solid transparent;
    position: absolute;
    border-bottom: 140px solid white;
    rotate: -25deg;
    z-index: 0;
    top: 5vh;
    right: 0;
}
.small-darkblue-triangle {
    border: 30px solid transparent;
    position: absolute;
    border-bottom: 70px solid #0D1F2B;
    rotate: -80deg;
    z-index: 0;
    top: 0;
    right: 0;
}
.second-small-darkblue-triangle {
    border: 35px solid transparent;
    position: absolute;
    border-bottom: 70px solid #0D1F2B;
    rotate: 135deg;
    z-index: 0;
    top: 10vh;
    right: 45vh;
}






.orange-triangle {
    border: 50px solid transparent;
    position: relative;
    border-bottom: 100px solid #E76012;
    rotate: 20deg;
    z-index: 0;
    left: 30vh;
}
.white-triangle {
    border: 90px solid transparent;
    position: relative;
    border-bottom: 140px solid #EAEAE9;
    rotate: 50deg;
    z-index: 0;
    bottom: 10vh;
    left: -20vh;
}

.green-light-triangle {
    border: 25px solid transparent;
    position: relative;
    border-bottom: 80px solid #14545B;
    rotate: -10deg;
    z-index: 0;
    top: -100vh;
    left: -28vh;
}

.shake {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
}
@keyframes shake {
  10%, 90% {
    transform: translate3d(-10px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(12px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-16px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(16px, 0, 0);
  }
}




</style>
<script>
import * as FormData from 'form-data'; 

import Mailgun from 'mailgun.js';  

export default{
    data(){
        return{     
            loading: true,       
            animated: false,
            fields:{
                name: null,
                company: null,
                numero: null,
                country: 'Portugal',
                size: null,
                email:null,
                title: null,
                description: null,
            },
            size_company:{
                pt:[
                    '< 5 empregados',
                    '5 - 20 empregados',
                    '20 - 50 empregados',
                    '50 - 250 empregados',
                    '> 250 empregados'
                ],                
                en:[
                    '< 5 employees',
                    '5 - 20 employees',
                    '20 - 50 employees',
                    '50 - 250 employees',
                    '> 250 employees'
                ]
            },
            window:{
                width: 0,
                height: 0,
            }
        }
    },
    mounted(){
        this.loading = false
    },
    created() {
        window.addEventListener('resize', this.handleResize);
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    computed:{
        countries(){
            return this.$t('countries')
        }
    },
    methods:{
        submit(){
            console.log("FOI")
            const mailgun = new Mailgun(FormData); 

            const mg = mailgun.client( 

                    { 

                        username: 'api',  

                        key: '7cd99b1d8469e35613685630190ce213-10eedde5-8c351df0', 

                        url:"https://api.eu.mailgun.net" 

                    } 

                ); 

            /*let message =   "Nova mensagem!<br/><br/>" +
                            "De: " + this.fields.email + "<br/><br/>" +
                            "Título: " + this.fields.title + "<br/><br/>" +
                            "Descrição: " + this.fields.description*/

            let message = "Nova mensagem!<br/><br/>" + 
                        "De: " + this.fields.name + "<br/><br/>" +
                        "Email: " + this.fields.email + "<br/><br/>" +
                        "Empresa: " + this.fields.company + "<br/><br/>" +
                        "Numero: " + this.fields.numero + "<br/><br/>" +
                        "País: " + this.fields.country + "<br/><br/>" +
                        "Tamanho: " + this.fields.size + "<br/><br/>" +
                        "Descrição: " + this.fields.description + "<br/><br/>";


            mg.messages.create('email.dreamdealtech.com', { 

            from: "info@dreamdealtech.com", 

            to: ["info@dreamdealtech.pt"], 

            subject: "Dreamdeal Website - Mensagem", 

            text: "Nova mensagem", 

            html: message

            }) 

            .then(msg => console.log(msg)) // logs response data 

            .catch(err => console.error(err)); // logs any error 

            this.success = true
            this.$router.push('/' + this.$i18n.locale + '/success')
        },
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;

            console.log(this.window.width)
        },
        clickHandlerMobile(){
            const self = this
            self.animated = true
            setTimeout(() => {
                self.animated = false
            }, 1000)
            this.$refs.formSender.$el.scrollIntoView({ behavior: 'smooth' })
        },
        clickHandler(){
            const self = this
            self.animated = true
            setTimeout(() => {
                self.animated = false
            }, 1000)
        }
    }
}
</script>